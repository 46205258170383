import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/Layout';
import UrlForm from '../components/UrlForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <UrlForm showLabel={true} mdxType="UrlForm" />
    <hr></hr>
    <p>{`Writing CSS is hard, especially at scale.`}</p>
    <p>{`CSS Stats provides `}<strong parentName="p">{`analytics and visualizations`}</strong>{` for your stylesheets.
This information can be used to `}<strong parentName="p">{`improve consistency`}</strong>{` in your design,
`}<strong parentName="p">{`track performance`}</strong>{` of your app, and `}<strong parentName="p">{`diagnose complex areas`}</strong>{` before
it snowballs out of control.`}</p>
    <p><a parentName="p" {...{
        "href": "./about"
      }}>{`Learn more about CSS Stats `}{`→`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      